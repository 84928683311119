import React, { useEffect, useState } from "react";
import styles from "./Banner.module.css";
import { useSelector } from "react-redux";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material";
import useJobsData from "../../../../api/useJobsData";

const Banner = ({ selectedLocation, setSelectedLocation }) => {
  const { getCategory, getLocations } = useJobsData();

  const locations = useSelector((state) => state.profile.locations);
  const [totalProfileCount, setTotalProfileCount] = useState(0);
  const categories = useSelector((state) => state.profile.categories);
  const locationsLoader = useSelector((state) => state.profile.loadingLocation);

  useEffect(() => {
    getLocations();
  }, []);

  useEffect(() => {
    if (locations) {
      getCategory(locations[0]);
    }
  }, [locations]);

  useEffect(() => {
    if (Array.isArray(categories)) {
      let count = 0;
      categories.forEach((category) => {
        count += category.total_candidates;
      });
      setTotalProfileCount(count);
    }
  }, [categories]);

  const handleLocation = (e) => {
    setSelectedLocation(e.target.value);
    getCategory(e.target.value);
  };

  return (
    <div className={styles.bannerContainer}>
      <div className={styles.subBannercontainer}>
        <div className={styles.bannerHeading}>
          Find Best Tech Talent – Free, <br /> Fast, and Effortless with TABuddy
        </div>

        <div className={styles.bannerSubHeading}>
          TABuddy’s FREE sourcing engine searches, curates, and organizes top
          tech talent into easy-to-review buckets. All you need to do? Review,
          Shortlist and engage
        </div>

        <div className={styles.bannerButtons}>
          <button className={styles.bannerButton2}>
            {" "}
            {totalProfileCount}+ Candiates Sourced
          </button>
        </div>
        {locationsLoader ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              gap: "0.5rem",
            }}
          >
            <div
              style={{
                backgroundColor: "#ffffff50",
                width: "200px",
                height: "60px",
                padding: "6px",
                borderRadius: "5px",
                border: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Skeleton width={"100%"} height={45}></Skeleton>
            </div>
            <Skeleton width={"100%"} height={35}></Skeleton>
          </div>
        ) : (
          <FormControl
            sx={{
              minWidth: "200px",
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: ".5rem",
            }}
            disabled={locationsLoader}
          >
            <Select
              // displayEmpty
              // inputProps={{ 'aria-label': 'Without label' }}
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                padding: "0px 5px",
                bgcolor: "#ffffff50", // Background color for the select dropdown
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#191919", // Border color
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#191919", // Border color on hover
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#191919", // Border color when focused
                },
              }}
              value={selectedLocation} // Control the value with state
              onChange={handleLocation} // Handle selection changes
            >
              {locations.length !== 0 ? (
                locations.map((data, index) => (
                  <MenuItem value={data} key={index}>
                    {data}
                  </MenuItem>
                ))
              ) : (
                <MenuItem>No Location Found</MenuItem>
              )}
            </Select>
            <span style={{ fontWeight: "500", fontSize: "15px" }}>
              (Select a location)
            </span>
          </FormControl>
        )}
      </div>
    </div>
  );
};

export default Banner;
