import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profiles: [],
  loadingProfiles: false,
  loadingLocation:false,
  locations:[],
  categories: [],
  skillCluster: [],
  loadingSkillCluster: false,
  loadingCategories: false,
  totalPages:1,
  isViewedProfilesCount:0,
  profileCountLoader:false,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,

  reducers: {
    setProfileLoader: (state, action) => {
      state.loadingProfiles = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
      state.loadingCategories = false;
    },
    fetchedProfiles: (state, action) => {
      state.loadingProfiles = false;
      state.profiles = action.payload;
    },
    resetProfiles: (state, action) => {
      state.profiles = [];
    },
    setSkillCluster: (state, action) => {
      state.skillCluster = action.payload;
      state.loadingSkillCluster = false;
    },
    setCategoriesLoading: (state, action) => {
      state.loadingCategories = action.payload;
    },
    setTotalPagedCount:(state, action) => {
      state.totalPages = action.payload;
    },
    setLoadingSkillCluster: (state, action) => {
      state.loadingSkillCluster = action.payload;
    },
    setResetProfile:(state)=>{
      state.profiles = [];
    },
    setIsViewedProfilesCount:(state, action)=>{
      state.isViewedProfilesCount = action.payload;
      state.profileCountLoader = false;
    },
    setProfileCountLoader:(state, action)=>{
      state.profileCountLoader = action.payload;
    },
    setLoadingLocation:(state, action)=>{
      state.loadingLocation = action.payload;
    },
    setLocation:(state, action)=>{
      state.locations = action.payload;
    },
    setResetProfileState:(state, action)=>{
      state.profiles = [];
      state.totalPages = 1;state.categories = [];
      state.skillCluster = []; 
    }
  },
});

export const {
  fetchedProfiles,
  setProfileLoader,
  setCategories,
  setSkillCluster,
  resetProfiles,
  setLoadingSkillCluster,
  setCategoriesLoading,
  setTotalPagedCount,
  setResetProfileState,
  setResetProfile,
  setIsViewedProfilesCount,
  setProfileCountLoader,
  setLoadingLocation,
  setLocation
} = profileSlice.actions;

export default profileSlice.reducer;